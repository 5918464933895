import * as Constants from 'constants/constants';
import { AppContext, AppContextType } from 'context';
import { useCustomTranslation } from 'hooks/useCustomTranslation';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AvailabilityDataType } from 'types';

const ACTIVE_BUTTONS_DEFAULT = [1, 2, 3, 4];
const FOUR_ROOMS = 4;

export const usePropertySelector = ({
  availabilityData,
  setHiddenUnits,
  hiddenUnits,
  setAreFiltersActive,
}: {
  availabilityData: AvailabilityDataType;
  setHiddenUnits: React.Dispatch<React.SetStateAction<string[]>>;
  hiddenUnits: string[];
  setAreFiltersActive: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [activeButtons, setActiveButtons] = useState<number[]>(
    ACTIVE_BUTTONS_DEFAULT,
  );
  const [toggleAvailability, setToggleAvailability] = useState<boolean>(false);

  const [minMaxSurfaces, setMnMaxSurfaces] = useState<number[]>([]);
  const [selectedSurface, setSelectedSurface] = useState<number[]>([]);

  const [minMaxPrice, setMinMaxPrice] = useState<number[]>([]);
  const [selectedBudget, setSelectedBudget] = useState<number[]>([]);

  const { selectedLanguage, authenticationStatus } =
    useContext<AppContextType>(AppContext);

  const { t } = useCustomTranslation(selectedLanguage.code, 'translations');

  const unitTypes = useMemo(() => {
    return [
      {
        id: 1,
        name: t('unitTypes.oneRoom'),
      },
      {
        id: 2,
        name: t('unitTypes.twoRooms'),
      },
      {
        id: 3,
        name: t('unitTypes.threeRooms'),
      },
      {
        id: 4,
        name: t('unitTypes.fourPlusRooms'),
      },
    ];
  }, [t]);

  useEffect(() => {
    setAreFiltersActive(
      !(
        ACTIVE_BUTTONS_DEFAULT.every((b) => activeButtons.includes(b)) &&
        selectedSurface[0] === minMaxSurfaces[0] &&
        selectedSurface[1] === minMaxSurfaces[1] &&
        selectedBudget[0] === minMaxPrice[0] &&
        selectedBudget[1] === minMaxPrice[1] &&
        !toggleAvailability
      ),
    );
  }, [
    activeButtons,
    minMaxPrice,
    minMaxSurfaces,
    selectedBudget,
    selectedSurface,
    setAreFiltersActive,
    toggleAvailability,
  ]);

  const livingUnits = useMemo(
    () =>
      availabilityData.units.filter(
        (u) => u?.mainUnitUse?.code === Constants.LIVING,
      ),
    [availabilityData.units],
  );

  //Loop all the properties, find and set the minimum and hte maximum surface and price
  useEffect(() => {
    const allSurfaces = livingUnits.map(
      (unitData) => unitData?.availability?.availableSurface ?? 0,
    );

    const minimumSurface = Math.min(...allSurfaces);
    const maximumSurface = Math.max(...allSurfaces);
    setMnMaxSurfaces([minimumSurface, maximumSurface]);
    setSelectedSurface([minimumSurface, maximumSurface]);

    const allPrices = livingUnits.map(
      (unitData) => unitData.availability?.priceList?.price ?? 0,
    );
    const minimumPrice = Math.min(...allPrices);
    const maximumPrice = Math.max(...allPrices);
    setMinMaxPrice([minimumPrice, maximumPrice]);
    setSelectedBudget([minimumPrice, maximumPrice]);
  }, [availabilityData.units, livingUnits]);

  //Filters
  useEffect(() => {
    let newHiddenUnits: string[] = [];

    //Check availability
    const unavailableProperties = livingUnits
      .filter((u) => u.availabilityStatus !== 'available')
      .map((u) => u.name);

    if (toggleAvailability) {
      newHiddenUnits = [...newHiddenUnits, ...unavailableProperties];
    }

    //Filter unit type
    const excludedTypeProperties = livingUnits
      .filter((u) => {
        const roomsNumber = u.residentialDetails?.roomsNumber ?? 0;
        const normalizedRooms =
          roomsNumber <= FOUR_ROOMS ? roomsNumber : FOUR_ROOMS;

        return !activeButtons.includes(normalizedRooms);
      })
      .map((u) => u.name);

    // Apply unit type filter only if it has been activated
    if (activeButtons.length < ACTIVE_BUTTONS_DEFAULT.length) {
      newHiddenUnits = [...newHiddenUnits, ...excludedTypeProperties];
    }

    const [minSurface, maxSurface] = selectedSurface;
    //Filter by range
    const excludedSurfaceProperties = livingUnits
      .filter(
        (u) =>
          (u.availability?.availableSurface ?? 0) < minSurface ||
          (u.availability?.availableSurface ?? 0) > maxSurface,
      )
      .map((u) => u.name);

    newHiddenUnits = [...newHiddenUnits, ...excludedSurfaceProperties];

    //Filter by budget
    const [minBudget, maxBudget] = selectedBudget;
    const excludedPriceProperties = livingUnits
      .filter(
        (u) =>
          (u.availability?.priceList?.price ?? 0) < minBudget ||
          (u.availability?.priceList?.price ?? 0) > maxBudget,
      )
      .map((u) => u.name);

    newHiddenUnits = [...newHiddenUnits, ...excludedPriceProperties];
    newHiddenUnits = [...new Set(newHiddenUnits)];

    setHiddenUnits(newHiddenUnits);
  }, [
    activeButtons,
    availabilityData.units,
    livingUnits,
    selectedBudget,
    selectedSurface,
    setHiddenUnits,
    toggleAvailability,
  ]);

  const filteredUnits = useMemo(
    () => livingUnits.length - hiddenUnits.length,
    [hiddenUnits.length, livingUnits.length],
  );

  const handleResetFilter = useCallback(() => {
    setActiveButtons(ACTIVE_BUTTONS_DEFAULT);
    setSelectedSurface([minMaxSurfaces[0], minMaxSurfaces[1]]);
    setSelectedBudget([minMaxPrice[0], minMaxPrice[1]]);
    setToggleAvailability(false);
    setAreFiltersActive(false);
  }, [minMaxPrice, minMaxSurfaces, setAreFiltersActive]);

  const menuRef = useRef<HTMLDivElement | null>(null);
  const [menuHeight, setMenuHeight] = useState(1000);

  useEffect(() => {
    const menuRefCurrentValue = menuRef.current;
    const updateHeight = () => {
      if (menuRefCurrentValue) {
        setMenuHeight(menuRefCurrentValue.getBoundingClientRect().height);
      }
    };

    const resizeObserver = new ResizeObserver(updateHeight);
    if (menuRefCurrentValue) resizeObserver.observe(menuRefCurrentValue);

    return () => {
      if (menuRefCurrentValue) resizeObserver.unobserve(menuRefCurrentValue);
    };
  }, [authenticationStatus, unitTypes, minMaxSurfaces, minMaxPrice]);

  useEffect(() => {
    const rootElement = document.getElementById('root');
    const handleOverflow = () => {
      const filterSelector = document.querySelector(
        '.filter-selector-fullscreen',
      );
      if (rootElement) {
        rootElement.style.overflowY = filterSelector ? 'hidden' : 'auto';
      }
    };

    handleOverflow();
    const observer = new MutationObserver(handleOverflow);
    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      if (rootElement) rootElement.style.overflowY = 'auto';
      observer.disconnect();
    };
  }, []);

  return {
    filteredUnits,
    activeButtons,
    setActiveButtons,
    selectedSurface,
    setSelectedSurface,
    minMaxSurfaces,
    selectedBudget,
    setSelectedBudget,
    minMaxPrice,
    setToggleAvailability,
    toggleAvailability,
    handleResetFilter,
    unitTypes,
    menuHeight,
    menuRef,
  };
};
