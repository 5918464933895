// eslint-disable-next-line no-restricted-imports
import icoWinInfo from './../ico-win-info.svg';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { AppContext, AppContextType } from 'context';
import { Orientation } from 'pages/KeyPlan/GestureWindow';
import { InfoCard } from 'pages/KeyPlan/components/infoCard';
import { useImageStatus } from 'pages/KeyPlan/hooks/useImageStatus';
import React, {
  Fragment,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Modal } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { AvailabilityDataType, UnitDataType } from 'types';
import { Marker } from 'types/configuration';

type ModalResponsiveType = {
  currentMarker: Marker;
  availabilityData: AvailabilityDataType;
  showModal: boolean;
  setShowModal: React.Dispatch<SetStateAction<boolean>>;
  windowIsFlipped: boolean;
  setWindowIsFlipped: React.Dispatch<SetStateAction<boolean>>;
};

export const ModalResponsive: React.FC<ModalResponsiveType> = ({
  currentMarker,
  availabilityData,
  showModal,
  setShowModal,
  setWindowIsFlipped,
  windowIsFlipped,
}) => {
  const { useStyles, t } = useContext<AppContextType>(AppContext);

  const classes = useStyles();

  const localClasses = useLocalStyles();

  const { imageIsLoading, imageIsAvailable } = useImageStatus(
    currentMarker?.title,
  );
  const unitData = useMemo<UnitDataType | undefined>(
    () => availabilityData.units.find((u) => u.name === currentMarker?.title),
    [availabilityData.units, currentMarker?.title],
  );

  const [
    opportunityProductDeletionInProgress,
    setOpportunityProductDeletionInProgress,
  ] = useState(false);

  return (
    <Modal
      className={localClasses.planimetryModal}
      show={showModal}
      onHide={() => {
        setShowModal(false);
      }}
      size={windowIsFlipped || imageIsAvailable ? 'xl' : 'sm'}
    >
      {imageIsLoading && <LoadingSpinner />}

      {!imageIsLoading && (
        <Fragment>
          <Modal.Header closeButton className={localClasses.modalHeader}>
            <Modal.Title>{currentMarker?.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body
            className="pt-1"
            style={{
              backgroundColor: '#f7f7f6',
              borderRadius: 7,
              fontSize: 13,
              fontFamily: 'Nunito sans',
            }}
          >
            <img
              src={icoWinInfo}
              alt="Close Info"
              className={localClasses.infoIcon}
              onClick={() => setWindowIsFlipped((prev) => !prev)}
            />
            {imageIsAvailable && !imageIsLoading && !windowIsFlipped && (
              <img
                src={`/image/planimetry/${currentMarker.title}.png`}
                alt={currentMarker.title}
                className="img-fluid"
              />
            )}
            {!imageIsAvailable && !imageIsLoading && !windowIsFlipped && (
              <div className={classes.unavailableImage}>
                <h5>{t('unavailableImage').toLocaleUpperCase()}</h5>
              </div>
            )}

            {windowIsFlipped && (
              <div className="py-1 p-sm-2 px-lg-5">
                <InfoCard
                  propertyName={currentMarker.title}
                  unitData={unitData}
                  windowOrientation={Orientation.Portrait}
                  opportunityProductDeletionInProgress={
                    opportunityProductDeletionInProgress
                  }
                  setOpportunityProductDeletionInProgress={
                    setOpportunityProductDeletionInProgress
                  }
                  isFullScreen={false}
                />
              </div>
            )}
          </Modal.Body>
        </Fragment>
      )}
    </Modal>
  );
};

const useLocalStyles = createUseStyles({
  modalHeader: {
    '& .btn-close': {
      backgroundColor: 'white!important',
      borderRadius: '100%!important',
    },
    '& .modal-title': {
      color: '#ffffffa1',
    },
    paddingBottom: '0!important',
    border: 'none',
    height: 50,
  },
  infoIcon: {
    top: -34,
    right: 45,
    position: 'absolute',
    cursor: 'pointer',
    width: 32,
    height: 34,
    marginLeft: 20,
  },
  planimetryModal: {
    '& .modal-content': {
      background: 'none',
      border: 'none',
      position: 'relative',
    },
  },
});
